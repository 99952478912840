<template>
    <div>
        <div class="text-xs-right">
            <v-btn class="blue" small round color="primary" :to="{name: 'njangi_groups.new_group'}" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Create New Project</span>
            </v-btn>
        </div>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aspernatur nesciunt eveniet?</p>
        <v-layout row wrap class="">
            <v-flex xs12 sm6 md4 lg3 v-for="item in groups" :key="item.id" class="pa-1">
                <v-hover>
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" :to="item.link" style="cursor: pointer; ">
                        <v-img
                                src=""
                                aspect-ratio="2.75"
                                height="150px"
                                :class="item.color"
                        > </v-img>

                        <v-card-title primary-title >
                            <h3 class="heading mb-0" >{{item.title}}</h3>
                            <div>
                                {{item.description}}
                            </div>
                        </v-card-title>

                    </v-card>
                </v-hover>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                groups: [
                    {
                        id: '1',
                        title: 'Nso People Association',
                        description: '',
                        color: 'orange',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'PSS MANKON EX-STUDENTS 2013 ',
                        description: '',
                        color: 'teal',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'PEOPLE CHANGING LIVES',
                        description: '',
                        color: 'blue',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'ENGINEERS IN THE MAKING',
                        description: '',
                        color: 'purple',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'PEOPLE IN THE MAKING',
                        description: '',
                        color: 'green',
                        link: '',
                        add_link: false
                    }
                ]
            }
        }

    }
</script>
